<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <p class="my-5" v-html="message"></p>
    <p class="mb-3">以下フォームよりログインしてシステムをご利用開始ください。</p>

    <v-card
      width="400"
    >
      <form-login
        :isloggedin="isloggedin"
        @basicLogin="login($event)"
      ></form-login>
    </v-card>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import axios from 'axios'
import $literals from '@/literals.js'
import { API_ENDPOINT } from '@/literals.js'
import Loader from '@/components/_Loader.vue'
import FormLogin from '@/components/_FormLogin.vue'

export default {
  components: {
    'loader': Loader,
    'form-login': FormLogin
  },

  props: {},

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      message: null,
      key: null,
      loading: false,
      loadingMessage: '',
      isloggedin: false
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    apiEndpoint() {
      return API_ENDPOINT + '/'
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.confirmSignup(this.$route.query.key)
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    confirmSignup(key) {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      axios({
        method: 'PUT',
        url: this.apiEndpoint + 'credential/activate/',
        params: { key: key }
      })
        .then(() => {
          this.message = '&#x1f389 ご利用登録が完了しました &#x1f389'
        })
        .catch(error => {
          if (
            error.response !== undefined &&
            error.response.status ==
              $literals.STATUS_CODE.errConfirmSignupExpired
          ) {
            alert(
              $literals.MESSAGE.failedConfirmSignupExpired +
                error.response.data +
                error.response.status
            )
          } else {
            alert($literals.MESSAGE.failedConfirmSignup + error)
          }
          // ホームへ
          this.$router.push({ name: 'home' })
        })
        .then(() => this.loading = false )
    },

    login(creadential) {
      //ログインに成功したらダッシュボードへリダイレクト
      sessionStorage.setItem('redirectTo', '/shop-admin/dashboard')
      this.$emit('basicLogin', creadential)
    }
  }
}
</script>

<style scoped>
</style>
